
<nz-steps [(nzCurrent)]="currentStep" nzSize="small">
    <nz-step nzTitle="Select"></nz-step>
    <nz-step nzTitle="Review"></nz-step>
    <nz-step nzTitle="Finalize"></nz-step>
  </nz-steps>
  
  <ng-container *ngTemplateOutlet="([select,review,confirm])[currentStep]"></ng-container>
  
  <ng-template #select>
    Select a CSV file or paste a list
    <form nz-form nzLayout="vertical" [formGroup]="frmImportReminders">
      <nz-collapse nzAccordion>
        <nz-collapse-panel
          nzHeader="Import from file"
          [nzActive]="true"
          >
          <nz-form-item>
            <nz-form-label>Select File (<a
                href="assets/mock_data/reminders/SampleImport.csv"
                download>Download Sample</a>)</nz-form-label>
            <nz-form-control>
              <input type="file" nz-input (change)="importCSV($event.target.files,true)" formControlName="importFile">
            </nz-form-control>
            
          </nz-form-item>
        </nz-collapse-panel>
        <nz-collapse-panel
          nzHeader="Import pasted CSV data"
          >
          <nz-form-item>
            <nz-form-label>
              Paste import 
            </nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please paste your contact data"
              >
              <small>(Format: Phone Number, Name, E-mail, Message, Datetime, Timezone) <i>All Columns Required!</i></small>
              <textarea formControlName="pastedImportData" nz-input rows="10"></textarea>
            </nz-form-control>
          </nz-form-item>
          
        </nz-collapse-panel>
      </nz-collapse>
    </form>
    
    <div class="bottom">
      <nz-card nzTitle="Parse Status" nzSize="small" style="margin-top:5px;">
        {{ parseStatus?.operation }}
        <nz-progress *ngIf="parseStatus?.percentComplete" [nzPercent]="parseStatus?.percentComplete" nzSize="small"></nz-progress>
        {{ parseStatus?.statusText }}
      </nz-card>
      <nz-divider></nz-divider>
      <button nz-button nzBlock nzType="primary" nzBlock [disabled]="!(importedData?.length >0 && busyParsing == false)" (click)="currentStep=1">Review Data and Alerts</button>
    </div>
  </ng-template>
  
  <ng-template #review>
    Review the import
    <form nz-form nzLayout="vertical" [formGroup]="frmImportReminders">
      <nz-collapse nzAccordion>
        <nz-collapse-panel
          nzHeader="Review Imported Reminders"
          [nzActive]="true"
          >
          Please review these Reminder from your import to ensure the data in each column is appropriate.
          <nz-table 
            #contactReviewTable 
            [nzData]="importPreviewData" 
            [nzLoading]="!importPreviewData" 
            [nzScroll]="{ y: '240px' }"
            [nzHideOnSinglePage]="true"
            >
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>E-mail</th>
                <th>Body</th>
                <th nzWidth="23%">Datetime</th>
                <th nzWidth="15%" >TZ</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of contactReviewTable.data">
              <td>{{ data?.full_name }}</td>
              <td>{{ data?.phone_number }}</td>
              <td>{{ data?.email }}</td>
              <td>{{ data?.body }}</td>
              <td>{{ data?.scheduleDateTime | date:"short" }}</td>
              <td>{{ data?.scheduleTimezone }}</td>
            </tr>
          </tbody>
          </nz-table>
        </nz-collapse-panel>
        <nz-collapse-panel
          nzHeader="Review Alerts ({{importAlerts.length}})"
          [nzDisabled] = "importAlerts.length == 0"
          >
          Please review the following alerts and resolve if necessary
          <nz-table 
            #contactProblemTable
            [nzData]="importAlerts" 
            [nzLoading]="!importAlerts" 
            [nzScroll]="{ y: '240px' }"
            [nzHideOnSinglePage]="true"
            >
            <thead>
              <tr>
                <th>Problem</th>
                <th>Context</th>
                <th>Resolution</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of contactProblemTable.data" [ngClass]="{'blocksImport':data?.blocksImport}">
              <td>{{data?.description}}</td>
              <td>{{data?.context}}</td>
              <td>{{data?.resolution}}</td>
            </tr>
          </tbody>
          </nz-table>
        </nz-collapse-panel>

      </nz-collapse>
    </form>
    <div class="bottom">
      <button nz-button nzBlock nzType="primary" nzBlock [disabled]="blockingImportAlerts > 0" (click)="currentStep=2">Finalize Import</button>
      <button nz-button nzBlock nzType="default" nzBlock (click)="currentStep=0">Back</button>
    </div>
  
   
  </ng-template>
  
  <ng-template #confirm>
    <nz-divider nzPlain nzText="Finalize Import"></nz-divider>
    <p>
      <b>Count of Records from import file:</b> {{ importedData?.length }}<br/>
      <b>Count of Contacts to be Created:</b> {{ ContactsToCreate?.length }}<br/>
      <b>Count of Reminders to be Created:</b>  {{ RemindersToStage?.length }}
    </p>

    <p>
      Reminders will be scheduled to be sent from <b>({{ authnz.activeNumberProfile.phone_number }})</b>
    </p>

    <div class="bottom">
      <button 
        nz-button 
        nzBlock
        nzType="primary" 
        nzBlock 
        (click)="finishImport()"
        >Finish Import
      </button>
      <button nz-button nzBlock nzType="default" nzBlock (click)="currentStep=1">Back</button>
    </div>
  </ng-template>

