import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AngularFirestore,  } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { getLogger } from '@shared/logging';
import { Observable, Subscription } from 'rxjs';
import { TextableContact, TextableContactList } from 'src/app/backported_types/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { TextableService } from 'src/app/services/textable.service';

enum Operation {
  ADD = 'add',
  REMOVE = 'remove'
}

const log = getLogger("BulkContactListComponent");
@Component({
  selector: 'app-bulk-contact-list',
  templateUrl: './bulk-contact-list.component.html',
  styleUrls: ['./bulk-contact-list.component.scss']
})
export class BulkContactListComponent implements OnInit {
  @Output() public onComplete: EventEmitter<void> = new EventEmitter<void>();
  @Input() public disabled: boolean = false;
  @Input() public selectedContacts: Observable<{[key: string]: boolean }>;
  public blockOptionsPopoverVisible: boolean = false;
  public Operation = Operation
  /**
   * Array of all contact list.
   */
  public availableContactLists: TextableContactList[] = [];
  private _contactListSub: Subscription;
  /**
   * Array of currently selected contacts based on bulk selection
   */
  private currentContactIds: string[];
  public frmBulkList: FormGroup;

  constructor(
    private textable: TextableService,
    private afs: AngularFirestore,
    private contactService: ContactsService) {

  }

  ngOnInit(): void {
    this.textable.contactLists$.subscribe((lists) => {
      this.availableContactLists = lists
    })

    this.frmBulkList = new FormGroup({
      "lists": new FormControl()
    });
  }
  /**
   * Attaches data to currentContactIds and open _contactListSub subscription 
   */
  public initTpl() {
    this._contactListSub = this.selectedContacts.subscribe((contactObj: { [key: string]: boolean }) => {
      this.currentContactIds = Object.keys(contactObj).filter(c => contactObj[c])
    })
  }
  /**
   * Listener for the visibility of the popover block and unsubscribes _contactListSub
   */
  public changeVisibility() {
    if (this.blockOptionsPopoverVisible === false) {
      this._contactListSub.unsubscribe();
    }
  }
  /**
   * Click handle for the Bulk Contact List Setter
   */
  public async execute(operation:Operation) {
    await this.updateContactDocumentListField(operation)
    this._contactListSub.unsubscribe();
  }
  /**
   * Updates the firestore contact document's list fields
   */
  private async updateContactDocumentListField(operation: Operation) {
    log.debug(`Updating contact-list related fields for ${JSON.stringify(this.currentContactIds)}`)
    const listId = this.frmBulkList.value.lists

    for (let id of this.currentContactIds) {
      this.getAndUpdateContactsListField(id, listId, operation)
    }

    this.blockOptionsPopoverVisible = false;
    this.textable.showNotifications('success', 'update', 'Bulk Contact Lists');
    await this.updateContactListCounts(listId)
  }
  /**
   * Handles the contact's lists array and makes updates to firebase.
   * @param contactId 
   * @param contactListId 
   * @param operation 
   */x
  private async getAndUpdateContactsListField(contactId: string, contactListId, operation: Operation) {
    const contactData = await (await this.afs.doc('contacts/' + contactId).get().toPromise()).data() as TextableContact
    const currList = contactData.lists ? contactData.lists : []

    if(operation === Operation.ADD){
      if(!currList?.includes(contactListId) || currList.length === 0){
        await this.afs.doc("contacts/" + contactId).update({lists:[...currList,contactListId]});
      }
    }else{
      const index = currList.indexOf(contactListId)
      if(index !== undefined){
        currList.splice(index,1)
        await this.afs.doc("contacts/" + contactId).update({lists:[...currList]});
      }
    }
  }
  /**
   * Updates the firestore contact-list document count field
   * @param id Contact List Id
   */
  private async updateContactListCounts(id) {
    await this.contactService.updateContactListCount(id)
  }
}
