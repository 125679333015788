import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthNZService } from 'src/app/core/authnz.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from "ng-zorro-antd/modal"
import { Router, ActivatedRoute } from '@angular/router';
import { TextableService } from 'src/app/services/textable.service';
import { vars } from 'src/app/app.constants';
import { environment } from '../../../environments/environment';
import { getLogger } from 'src/shared/logging';
import { ContactsService } from 'src/app/services/contacts.service';
import { TextableDate } from 'src/classes/TextableDate';
import { TextableContactList } from 'src/app/backported_types/contact';

const log = getLogger("ContactsComponent");

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  host: {
    "class": "app-view app-view-two-pane"
  }
})
export class ContactsComponent implements OnInit {

  public phoneMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; //ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.

  @ViewChild('txtFilter') txtFilter: ElementRef;

  isUpdatingContact: boolean = false;
  isAddingContact: boolean = false;
  frmContactDetails: FormGroup;
  frmNewContact: FormGroup;

  environment: any = environment;

  mdlTag = false;
  mdlDeleteAllContacts = false;
  drwNewContact = false;
  drwImport = false;
  drwContactDetail = false;
  guideVisibleBool = false;

  
  contactAdded = false;
  tagList$;
  newTagList$;
  newTagList2$;
  activeContact: any;
  contactsLoadingMessage: string = "Loading Contacts...";
  skipFirstLineImport: boolean = false;

  //phonePrefix should change depending on location in the future.
  phonePrefix:string = '+1';
  countryCodes = vars.countryCodes;
  contactRequested: boolean;
  /**
   * Array of all contact list objects.
   */
  public availableContactLists: TextableContactList[] = [];
  private initialActiveLists: string[] = [];
    
  constructor(
    private afs: AngularFirestore,
    private authnz: AuthNZService,
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private textable: TextableService,
    private nzModal: NzModalService,
    private contactService: ContactsService
  ) { }

  ngOnInit() {
      this.textable.contactLists$.subscribe((lists) => {
        this.availableContactLists = lists.filter(list => !list.isDeleted)
      })
      //this.tagList$ = this.textable.getTags();
      //this.newTagList$ = this.textable.getTags();
      //this.newTagList2$ = this.textable.getTags();
      this.frmContactDetails = new FormGroup({
      "full_name": new FormControl(null,[
        Validators.required,
        Validators.minLength(2),
      ]),
      "phone_number": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.phone),// Require length of 12(on read) then 14 (on write)
      ]),
      "email": new FormControl(null, [
        Validators.email
      ]),
      "tags": new FormControl(null),
      "id": new FormControl(null),
      "optedOut": new FormControl(null),
      "optedOutAt": new FormControl(null),
      "companyName": new FormControl(null),
      "notes": new FormControl(null),
      "title": new FormControl(null),
      "lists": new FormControl(null),
    });   

    this.frmNewContact = new FormGroup({
      "full_name": new FormControl(null,[
        Validators.required,
        Validators.minLength(2),
      ]),
      "phone_number": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.phone),// Require length of 12(on read) then 14 (on write)
      ]),
      "email": new FormControl(null, [
        Validators.email
      ]),
      "tags": new FormControl([]),
      "companyName": new FormControl(null),
      "notes": new FormControl(null),
      "title": new FormControl(null),
      "lists": new FormControl()
    });

    this.route.params.subscribe(params => {
      if(params['id']){
        log.debug("Contact requested by URL route parameter: " + params['id']);
        this.afs.doc('contacts/'+params['id']).get().subscribe((contact)=>{
          let c = contact.data() as any; // TODO: TXBDEV-193 Refactor this to use TextableUser interface when TextableCommon become available in PF
          c.id = contact.id;
          this.authnz.setActiveNumber(c.uid);
          this.setActiveContact(c);
          this.contactRequested = true;
        });
      }
    });

    this.authnz.contextChanged.subscribe(({activeNumberProfile, previousActiveNumberProfile})=>{
      if (previousActiveNumberProfile.phone_number !== activeNumberProfile.phone_number) {
        log.debug("Changing active number from '" +  previousActiveNumberProfile.phone_number + "' to '" + activeNumberProfile.phone_number + "'");
        this.setActiveContact(null);
      }
    });
  }

  setActiveContact(contact) {
    log.debug("Setting active contact to " ,contact);
    //this.drwContactDetail = true; not using contact details drawer
    this.activeContact = contact;
    this.frmContactDetails.reset();

    if(contact) {
      this.frmContactDetails.patchValue(contact);
      this.initialActiveLists = [...contact.lists]
    }
  }

  setOptedOutDate() {
    if (this.frmContactDetails.value.optedOut) {
      this.frmContactDetails.get('optedOutAt').setValue(TextableDate.now())
    } else {
      this.frmContactDetails.get('optedOutAt').setValue(null)
    }
  }

  deleteContact(contact) {
    this.textable.delete("contacts", contact.id, { title: 'Success', subtitle: 'Contact: ' + contact.full_name + ' (' + contact.phone_number+ ')'+ ' has been deleted.'}).then((res) => {
      this.closeDrawer();
    })
  }

  async deleteAllContacts() {
    this.contactService.DeleteAllContacts();
  }

  addContact() {
    let exist = 0;
    this.checkIfNewTags(this.frmNewContact);
    this.isAddingContact = true;
    let contact = this.frmNewContact.value;

    contact.uid = this.authnz.activeNumberUID;
    contact.phone_number = this.textable.addCountryCode(contact.phone_number,this.phonePrefix);


    this.afs.collection('contacts', ref =>
      ref.where('phone_number', '==', this.frmNewContact.value.phone_number)
      .where('uid', '==', this.authnz.activeNumberUID))
      .get().forEach(array => {        
        if (array.empty == true) {
          this.afs.collection('contacts')
          .add(contact)
          .then((ref)=>{
            this.isAddingContact = false;
            this.contactAdded = true;

            // Contact List Process
            if(contact.lists.length > 0){
              this.updateContactList(contact.lists)
            }
          })
          .catch((err)=>{
            this.isAddingContact = false;
            this.contactAdded = false;
          });
        } else {
          this.textable.showCustomNotifications('err', { title: 'error', subtitle: "Duplicate Phone Number!" });
          this.isAddingContact = false;
          this.contactAdded = false;
        }
    })
  }

  updateContactList(contactListId:string[]){
    for(let listId of contactListId){
      this.contactService.updateContactListCount(listId)
    }
  }



  updateContactDetails() {
    //this.checkIfNewTags(this.frmContactDetails); 
    for (const i in this.frmContactDetails.controls) {
      this.frmContactDetails.controls[i].markAsDirty();
      this.frmContactDetails.controls[i].updateValueAndValidity();
    }

    if (!this.frmContactDetails.valid) {
      this.notification.create(
        'error',
        'Contact Not Updated',
        'Please correct the issues before updating contact.'
      );
    } else {
      this.isUpdatingContact = true;
      this.frmContactDetails.value.phone_number = this.textable.addCountryCode(this.frmContactDetails.value.phone_number,this.phonePrefix);

      //check if the phone number is contacts collection
      this.afs.collection('contacts', ref =>
        ref.where('phone_number', '==', this.frmContactDetails.value.phone_number)
        .where('uid', '==', this.authnz.activeNumberUID))
        .get().forEach(array => {
          //checks to see if current contact to let is save the number.
          let isSameContact = false;
          array.docs.forEach(doc => {
            if (doc.id == this.frmContactDetails.value.id) {
              isSameContact = true;
            }
          })
          //if not duplicate continue the contact update
          if (array.empty == true || isSameContact) {
            this.afs.doc('contacts/' + this.activeContact.id).update(this.frmContactDetails.value)
              .then(() => {
                this.isUpdatingContact = false;
                const listsToUpdate = [...new Set(this.initialActiveLists.concat(this.frmContactDetails.value.lists))] 
                this.updateContactList(listsToUpdate)
                this.notification.create(
                  'success',
                  'Contact Updated',
                  'The contact has been updated successfully.'
                );
              }, () => {
                this.notification.create(
                  'error',
                  'Contact Not Updated',
                  'The contact has NOT been updated. Please try again.'
                );
              });
          } else {
            this.textable.showCustomNotifications('err', { title: 'error', subtitle: "Duplicate Phone Number!" });
            this.isUpdatingContact = false;
          }
        })
    }
  }

  closeDrawer(){
    this.frmNewContact.reset();
    this.contactAdded = false;
    this.isAddingContact = false;
    this.drwNewContact = false;
    this.drwContactDetail = false;
  }


  



  
  /////////////////
  // TAG FUNCTIONS
  /////////////////

  createTag(tag) {
    this.textable.create('tags', {
      tag: tag,
      uid: this.authnz.activeNumberUID
    }).catch(err => console.log(err))
  }
  deleteTag(tag) {
    let msg = "Tag has been marked for deletion. This may take several minutes to complete processing if you have a lot of contacts."
    this.textable.delete('tags', tag.id, {
      title: 'Success',
      subtitle: msg
    })
      .catch(err => console.log(err))
  }
  removeTag(oldTag,tag) {
    let newTag = []
    oldTag.forEach(t => {
      if (t !== tag.tag) {
        newTag.push(t)
      }
    });
    return newTag
  }

  checkIfNewTags(form) {    
    if (form.value.tags) {
      this.makeTagsUpperCase(form)

      form.value.tags.forEach(tag => {
        tag = tag.toString().toUpperCase();
        this.afs
          .collection('tags', ref => ref.where('uid', '==', this.authnz.activeNumberUID).where('tag', '==', tag))
          .get().forEach(refTag => {
            if (refTag.empty) {
              this.createTag(tag.toString().toUpperCase())
            }
          });
      });
    }
  }
  makeTagsUpperCase(form) {
    let tags = form.get('tags').value;
    let ucTags = [];
    tags.forEach(tag => {
      //console.log(ucTags.indexOf(tag.toString().toUpperCase()))
      if (ucTags.indexOf(tag.toString().toUpperCase()) == -1) {
        ucTags.push(tag.toString().toUpperCase());
      }
    })
    form.get('tags').setValue(ucTags)
  }

  //////////////////
  // Open Modal
  ////////////////
  openMdlTag() {
    this.mdlTag = true;
  }
}


