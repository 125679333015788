<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
    <div nz-row>
      <div nz-col class="left-pane">
        <app-address-book 
          [activated]="true" 
          [optedOut]="false" 
          (contactSelected)="!contactRequested ? setActiveContact($event) : contactRequested = false" 
          [perPage]="10" 
          [clearOnSelect]="false">
        </app-address-book>
      </div>
      <div nz-col class="right-pane" id="contacts-pane">
        <p class="text-center pad-25" *ngIf="!activeContact">Select a contact from the list.</p>
        <div class="contact-details pad-25" *ngIf="activeContact">
            <form nz-form nzLayout="vertical" [formGroup]="frmContactDetails" (ngSubmit)="updateContactDetails()">
              <nz-form-item>
                <nz-form-label nzRequired>Full Name</nz-form-label>
                <nz-form-control 
                  nzHasFeedback
                  nzErrorTip="Please enter a contact name."
                  >
                  <input nz-input formControlName="full_name" placeholder="John Doe" />
                </nz-form-control>
              </nz-form-item>

              <nz-row nzGutter="12">
                <nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label nzRequired>Phone Number</nz-form-label>
                    <nz-form-control 
                      nzHasFeedback
                      nzErrorTip="Invalid number format. Must match e.164 format. (e.g. +1 (555) 555-5555)"
                      >
                      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                        <ng-template #addOnBeforeTemplate>
                          <nz-select [(ngModel)]="phonePrefix" style="width: 60px" [ngModelOptions]="{standalone: true}" [nzDropdownMatchSelectWidth]="false">
                            <nz-option style="width: 200px" nzCustomContent *ngFor="let country of countryCodes" [nzLabel]="country.code" [nzValue]="country.code">{{country.name}}<span style="color: lightgray"> ({{country.code}})</span></nz-option>
                          </nz-select>
                        </ng-template>
                        <input nz-input placeholder="(555) 555-5555" type="text" formControlName="phone_number" type="tel"
                        [textMask]="{mask: phoneMask}">
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label>E-mail</nz-form-label>
                    <nz-form-control 
                      nzHasFeedback
                      nzErrorTip="Please enter a valid e-mail address."
                      >
                      <input nz-input formControlName="email" placeholder="john.doe@gmail.com" type="email" />
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>

              <nz-row nzGutter="12">
                <nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label>Company Name</nz-form-label>
                    <nz-form-control>
                      <input nz-input formControlName="companyName"/>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
                <nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label>Title</nz-form-label>
                    <nz-form-control>
                      <input nz-input formControlName="title" />
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>


              <nz-form-item>
                <nz-form-label>Notes</nz-form-label>
                <nz-form-control>
                  <textarea nz-input formControlName="notes" 
                  rows="3"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
              
              <div *ngIf="this.authnz.currentSecurityProvider.canAccessContactLists()">
                
                <nz-form-item *ngIf="availableContactLists?.length > 0">
                  <nz-form-label>Contact Lists</nz-form-label>
                  <nz-form-control>
                    <nz-select  nzMode="multiple" formControlName="lists" nzPlaceHolder="Select a Contact List">
                      <ng-container *ngFor="let list of availableContactLists">
                          <nz-option nzValue="{{list.id}}" nzLabel="{{list.listName + ' (' + (list?.count >= 0 ? list.count : '0') + ')' }}" >
                          </nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- DISABLED TAGS
              <nz-form-item>
                  <nz-form-label>Tags</nz-form-label>
                  <nz-form-control>
                    <nz-select nzMode="tags" nzPlaceHolder="Tags" formControlName="tags" style="text-transform:uppercase">
                      <nz-option style="text-transform:uppercase" *ngFor="let tag of newTagList2$ | async" [nzLabel]="tag.tag"
                        [nzValue]="tag.tag"> </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              -->

                <nz-form-item *ngIf="activeContact.optedOut != true">
                  <label nz-checkbox (ngModelChange)="setOptedOutDate()" formControlName="optedOut">Opted Out</label>
                </nz-form-item>
              <nz-divider></nz-divider>

              <nz-form-item>
                <app-opted-out-banner [contact]="activeContact" [page]="'contacts'"></app-opted-out-banner>
                <app-consent-message-banner [contact]="activeContact" [page]="'contacts'"></app-consent-message-banner>
                <app-integration-message-banner [contact]="activeContact" [page]="'contacts'" ></app-integration-message-banner>
              </nz-form-item>


              <button [disabled]="!frmContactDetails.valid" nz-button nzType="primary" nzBlock [nzLoading]="isUpdatingContact">Update Contact Details</button>
              <button *ngIf="this.authnz.currentSecurityProvider.canDeleteContacts()" style="margin-top: 6px" nz-button nzType="danger" nzBlock [nzLoading]="isUpdatingContact"
              nz-popconfirm [nzPopconfirmTitle]="deleteInfo" (nzOnConfirm)="deleteContact(activeContact)" 
              >Delete Contact</button>
              <ng-template #deleteInfo>
                  <i nz-icon nzType="question-circle-o" style="color: red;"></i>&nbsp;&nbsp;&nbsp;
                  <span>Are you sure you want to delete this contact?</span> 
                <br><i><small>This will also delete all conversations with this contact.</small></i>
              </ng-template>
              <app-sender-blocker
              class="contactActionButton"
              [sender]="activeContact"
              displayStyle="both">
            </app-sender-blocker>
            </form>
        </div>
      </div>
    </div>
</nz-card>
<ng-template #extraTemplate>
  <nz-button-group [nzSize]="size">
    <!-- DISABLED TAGS
      <button nz-button nzType="primary" (click)="openMdlTag()"><i class="far fa-tag"></i> Tags</button>
    -->
    <button *ngIf="this.authnz.currentSecurityProvider.canImportContacts()" nz-button nzType="primary" (click)="drwImport=true"><i class="far fa-file-import"></i> Import</button>
    <button nz-button nzType="primary" (click)="drwNewContact=true;frmNewContact.reset();"><i class="far fa-comment-plus"></i> New Contact</button>
    <button *ngIf="this.authnz.currentSecurityProvider.canDeleteAllContacts()" nz-button nzType="primary" (click)="mdlDeleteAllContacts = true"><i class="fas fa-cog"></i></button>
  </nz-button-group>
</ng-template>


<nz-drawer [nzClosable]="true" [nzVisible]="drwNewContact" nzPlacement="right" nzTitle="Add New Contact"
  (nzOnClose)="closeDrawer()" [nzWidth]="500">
  <ng-container *nzDrawerContent>
    <div class="pad-15" *ngIf="!contactAdded">
      <div class="pad-15">
        <form nz-form nzLayout="vertical" [formGroup]="frmNewContact" (ngSubmit)="addContact()">
          <nz-form-item>
            <nz-form-label nzRequired>Full Name</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a valid name. (Min. 3 chars.)"
              >
              <input nz-input formControlName="full_name" placeholder="John Doe" type="text" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired>Phone Number</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Invalid number format. Must match e.164 format. (e.g. +1 (555) 555-5555)"
              >
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate2">
                    <ng-template #addOnBeforeTemplate2>
                      <nz-select [(ngModel)]="phonePrefix" style="width: 60px" [ngModelOptions]="{standalone: true}" [nzDropdownMatchSelectWidth]="false">
                        <nz-option style="width: 200px" nzCustomContent *ngFor="let country of countryCodes" [nzLabel]="country.code" [nzValue]="country.code">{{country.name}}<span style="color: lightgray"> ({{country.code}})</span></nz-option>
                      </nz-select>
                    </ng-template>
                    <input nz-input placeholder="(555) 555-5555" type="text" formControlName="phone_number" type="tel"
                    [textMask]="{mask: phoneMask}">
                  </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>E-mail Address</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a valid e-mail address."
              >
              <input nz-input formControlName="email" placeholder="john.doe@gmail.com" type="email" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Company Name</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="companyName"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Title</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="title" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Notes</nz-form-label>
            <nz-form-control>
              <textarea nz-input formControlName="notes" 
              rows="3"
              ></textarea>
            </nz-form-control>
          </nz-form-item>


          <nz-form-item *ngIf="this.authnz.currentSecurityProvider.canAccessContactLists()">
            <nz-form-label>Contact Lists</nz-form-label>
            <nz-form-control>
              <nz-select class="contact-list-select" *ngIf="availableContactLists?.length > 0" nzMode="multiple" nzPlaceHolder="Select a Contact List" formControlName="lists">

                <ng-container *ngFor="let list of availableContactLists">
                    <nz-option nzValue="{{list.id}}" nzLabel="{{list.listName + ' (' + (list?.count >= 0 ? list.count : '0') + ')' }}" >
                    </nz-option>
                </ng-container>
  
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <!-- DISABLED TAGS
          <nz-form-item>
            <nz-form-label>Tags</nz-form-label>
            <nz-form-control>
              <nz-select nzMode="tags" nzPlaceHolder="Tags" formControlName="tags" style="text-transform:uppercase">
                <nz-option style="text-transform:uppercase" *ngFor="let tag of newTagList$ | async" [nzLabel]="tag.tag"
                  [nzValue]="tag.tag"> </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
            -->
          <button nz-button nzType="primary" nzBlock [nzLoading]="isAddingContact" [disabled]="!frmNewContact.valid">Add Contact</button>
        </form>
      </div>
    </div>
    <div class="pad15 text-center" *ngIf="contactAdded">
      <i class="far fa-shield-check" style="font-size:80px;color:green;margin-top:100px;margin-bottom:25px;"></i>
      <p>New contact saved!</p>
      <button nz-button nzType="primary" (click)="closeDrawer()">Close</button>
    </div>
  </ng-container>
</nz-drawer>

<nz-drawer [nzClosable]="true" [nzVisible]="drwImport" nzPlacement="right" nzTitle="Import Contacts"
  (nzOnClose)="drwImport=false" [nzWidth]="800">
  <ng-container *nzDrawerContent>
    <app-contact-import
      *ngIf="drwImport"
      (ContactImportQueued) = "drwImport=false"
      >
    </app-contact-import>
  </ng-container>
</nz-drawer>

<nz-modal [(nzVisible)]="mdlTag" nzTitle="Tags" (nzOnCancel)="mdlTag=false" [nzFooter]="modalFooter">
  <ng-container *nzModalContent>
    Select Tags to delete.<br><br>
    <nz-tag *ngFor="let tag of tagList$ | async;" nz-popconfirm
      nzPopconfirmTitle="Deleting this tag also removes it from all users. Are you sure?" (nzOnConfirm)="deleteTag(tag)">
      {{ tag.tag }}
    </nz-tag>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="mdlDeleteAllContacts" nzTitle="Settings" (nzOnCancel)="mdlDeleteAllContacts=false" [nzFooter]="modalFooter">
  <ng-container *nzModalContent>
    <div nz-row>
        <div nz-col nzSpan="16">
          <b>Delete All Contacts</b>
          <p>
            This option is non-reversible. All contacts and their conversations will be permanently deleted.
          </p>
        </div>
        <div nz-col nzSpan="8">
            <button style="margin-top: 12px;margin-bottom:12px" nz-popconfirm nzPopconfirmTitle="Confirm delete all contacts." 
            (nzOnConfirm)="deleteAllContacts(); mdlDeleteAllContacts = false" nz-button nzType="danger" nzBlock>Delete</button>
          </div>
    </div>
  </ng-container>
</nz-modal>

  <ng-template #modalFooter>
      <!-- empty footer-->
    </ng-template>

    <ng-template #pageTitleTemplate>
      Contacts <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
    </ng-template>

    <nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
      <ng-container *nzModalContent>
        <app-user-guides [selectedGuide]="'Contacts'"></app-user-guides>
      </ng-container>
    </nz-modal>
    <ng-template #guideFooter>
    
    </ng-template>