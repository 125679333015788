<button nz-button nzType="default" nz-tooltip [nzTooltipTitle]="'Bulk Contact List'" nz-popover nzPopoverTrigger="click"
    [nzPopoverContent]="blockOptionsPopover" nzPopoverPlacement="right"
    [(nzPopoverVisible)]="blockOptionsPopoverVisible" [disabled]="disabled" (click)="initTpl()"
    (nzPopoverVisibleChange)="changeVisibility()">
    <i class="fas fa-list"></i>
</button>



<ng-template #blockOptionsPopover>
    <div class="blockOptionsPopover">
        <nz-alert nzType="info" nzMessage="Bulk Contact List Setter"
            nzDescription="This will set all checked contacts to be added or removed from a list." nzShowIcon>
        </nz-alert>

        <form nz-form [nzLayout]="'inline'" [formGroup]="frmBulkList">
            <nz-form-item class="select">
                <nz-select *ngIf="availableContactLists?.length > 0"
                    nzPlaceHolder="Select a Contact List" formControlName="lists" [nzPlacement]="placement">
                    <ng-container *ngFor="let list of availableContactLists">
                        <nz-option *ngIf="!list.isDeleted" nzValue="{{list.id}}"
                            nzLabel="{{list.listName + ' (' + (list?.count >= 0 ? list.count : '0') + ')' }}">
                        </nz-option>
                    </ng-container>
                </nz-select>
            </nz-form-item>

            <div class="button-container">
                    <button nz-button nzType="primary" (click)="execute(Operation.ADD)">Add</button>
                    <button nz-button nzType="danger" (click)="execute(Operation.REMOVE)">Remove</button>
            </div>

        </form>





    </div>
</ng-template>